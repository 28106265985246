import React from "react"
import Footer from './Footer';
import classnames from 'classnames';
import '../styles/guide.scss';

const Guide = ({ children, tab }) => {
  const location = {};
  return (
    <div className="guide">
      <div className="header">
	<div className="container">
	  <div className="brand">
    	    <a href="/">
	      <h1>Pictarize</h1>
	      <h2>Augmenting any pictures</h2>
	    </a>
	  </div>
	  <div className="items">
            <div className="item">
    	      Studio Manual:
	    </div>
	    <div className={classnames({selected: tab==='editor'}, "item")}>
	      <a href="/guide/editor">Editor</a>
	    </div>
	    <div className={classnames({selected: tab==='publish'}, "item")}>
	      <a href="/guide/publish">Publish</a>
	    </div>
	    <div className={classnames({selected: tab==='script'}, "item")}>
	      <a href="/guide/script">Script</a>
	    </div>
	  </div>
	</div>
      </div>

      <div className="main">
	<div className="doc container">
	  <div className="content">

	    <div className="support">If you have any questions, feel free to ask in the <a href="https://gitter.im/web-augmented-reality/pictarize" target="_blank" rel="noreferrer">Discussion Board</a></div>

	    {children}
	  </div>
	</div>
      </div>

      <Footer/>
    </div>
  )
}

export default Guide;
