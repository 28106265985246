import React from "react";
import SEO from "../../components/seo"

import publishBuildsImg from '../../images/guide-publish-builds.png';
import publishPreviewImg from '../../images/guide-publish-preview.png';
import publishTestImg from '../../images/guide-publish-test.png';
import publishStatImg from '../../images/guide-publish-stat.png';
import Guide from '../../components/Guide';

const EditorGuide = () => {
  return (
    <Guide tab="publish">
      <SEO title="Pictarize | Editor Manual | Publish Guide"/>
      <div className="publish-guide">
	<h1>Publish Guide</h1>
	<p>You can publish projects directly inside the studio. Pictarize will pack your project into an optimized distribution and serve it over CDN (content delivery network) for best performance! It's critical for web applications because users are impatient.</p>

	<p>Once you publish, a publicly accessible URL for your web app will be generated.</p>

	<p>We also provide usage statistics so you can track your app performance.</p>

	<h2>Project Builds</h2>
	<p>When you click the "Publish..." button on the menu bar, it will bring up the publishing popup like below:</p>
	<p>
	  <img alt="pictarize-publish" src={publishBuildsImg} />
	</p>

	<p>
	  Building a project is as simple as clicking a "Build" button. Once it's done, You can view the effects in mobile devices. If you click "Preview" now, another popup like this will appear:
	</p>

	<p>
	  <img alt="pictarize-publish" src={publishPreviewImg} />
	</p>
      
	<p>
	  A publicly accessible URL will be generated each time you build. You can now open this URL with your mobile device. The QR code is there for your convenient. You can also see your target images on the popup, and they are also there for you to test the effects easily. When you open the URL with your device, you will see something like below. Point the camera to the target images to see the result! 
	</p>

	<p>
	  <img alt="pictarize-publish" className="mobile" src={publishTestImg}/>
	</p>

	<h2>Release</h2>
	<p>
	  <strong>The Build preview is mostly for you to test out the effects in a real device, and they are NOT expected to be used in production.</strong> <strong>That URL changes everytime you build and they are not guaranteed to be long lasting.</strong> Also, there will be a "DEMO ONLY" notice on the web page.
	</p>
	<p>If you are satisifed with the result, you need to release the project. To do that, you click the "Release" button next to your build. If this is the first time you release a particular project, you will then need to choose a unique and permanent URL for the app.</p>
	<p>The URL format is <strong>https://XXX.app.pictarize.com</strong>, in which XXX can be whatever you like. <strong>You should use this URL for your production.</strong> Later when you update the project, you can rebuild and release to the same URL. You can also easily rollback to previous versions if needed.
	</p>

	<h2>Deliver to end users</h2>
	<p>
	  Now you have a permanent app URL ready, so how do you deliver to end users. Basically, you need to send them the release URL so they can open your app, and then scan your target images to view the effects. 
	</p>
	<p>
	  In practice, one common approach is to attach a QR code (i.e. your app URL) directly on your printed materials. For example, if it is an web AR business card, you can put the QR code on the card and say something like "AR effect here". This allows a seamless user experience because the users will be able to open your app through your card and then scan it right away. 
	</p>

	<p>
	  To make your life easier, you can directly download the app QR code from the studio. However, some people prefer to customize the look of the QR code like applying a different color or attach a logo on it, which is also fine.
	</p>

	<h2>Monitor</h2>
	<p>
	  Once your application is published, you will be able to monitor the daily usages in the statistics.
	</p>
	<p>
	  <img alt="pictarize-publish" src={publishStatImg}/>
	</p>
      </div>
    </Guide>
  )
}

export default EditorGuide;

