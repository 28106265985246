import React from "react"

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
	<div className="left">
	  © 2021 pictarize.com
	</div>
	<div className="right">
	  <a href="/terms">Terms of use</a>
	  <a href="/privacy-policy">Privacy Policy</a>
	</div>
      </div>
    </div>
  )
}

export default Footer;
